import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Club from '../views/Club.vue'
import BuyBeta from '../views/BuyBeta.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { scrollToTop: true }
  },
  {
    path: '/buy-placeholder',
    name: 'Buy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "buy" */ '../views/Buy.vue'),
    meta: { scrollToTop: true }
  },
  {
    path: '/buy',
    name: 'BuyBeta',
    component: BuyBeta,
    meta: { scrollToTop: true }
  },
  {
    path: '/club',
    name: 'Club',
    component: Club,
    meta: { scrollToTop: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound
  }
]
const scrollBehavior = function(to) {
  if (to.hash) {
    console.log('to.hash found in:', to.hash)
    return {
      el: to.hash,
      behavior: 'smooth'
    }
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior
  
})

export default router
