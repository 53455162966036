import axios from "axios";

import axiosRetry from 'axios-retry';
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay });

class NftApiService {
    client = axios.create({
        //baseURL: "https://wbrh61g8z7.execute-api.us-east-2.amazonaws.com/prod",
        baseURL: "https://api.2600.quest",
        //baseURL: "https://1eu1fc5js9.execute-api.us-east-2.amazonaws.com",
        timeout: 30000, // 5 seconds seems to be too low for the nftmaker :/
        //withCredentials: true,
        headers: {
            "Content-type": "application/json",
            "x-api-key": "t9K0WOPazQ7CyMleiNmZs7Jdgx4eY7cW1zMX7eFq",
        }
    });

    getAddressForRandomNftSale(data) {
        console.log('getAddressForRandomNftSale', data);
        return this.client.post("/GetAddressForRandomNftSale", data);
    }

    checkAddress(address) {
        return this.client.get(`/checkaddress?address=${address}`);
    }
}

export default new NftApiService();