<template>
  <main class="px-3">
    <!-- hero -->
    <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
        <div class="col-md-7 col-lg-65 mx-auto my-2 mb-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="currentColor" class="bi bi-lock-fill my-3" viewBox="0 0 16 16">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                </svg>

            <h1 class="display-4 fw-normal">The 2600 Club Member Area</h1>

             <p class="lead fw-normal"><a href="https://club.2600.quest" class="text-light">Enter the exclusive club for verified Blue Box NFT owners</a>.</p>
        </div>
    </div>
  </main>
</template>

<script>


export default {
  name: 'Club',

}
</script>
