<template>
  <main class="px-3">
    <!-- hero -->
    <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
        <div class="col-md-12 col-lg-12 mx-auto my-2 mb-5">


            <div v-if="api_error">
              <p class="alert alert-danger" role="alert">{{api_error_msg}}</p>
            </div>

            <div v-if="!submitted">
              <h1 class="display-2">Buy Now</h1>
              <p class="lead">The Blue Box NFTs are now available for purchase!<br>You can buy the Blue Box NFTs in two alternative ways.</p>


<div class="accordion" id="paymentType">
  <div class="accordion-item">  
              <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    1. Fully automated, on-chain purchase
                  </button>             
                </h2>
              <div id="collapseOne" class="accordion-collapse collapse show"  aria-labelledby="headingOne" data-bs-parent="#paymentType">
                <div class="accordion-body bg-dark text-light text-start">           
                  <p>Either pay multiples of 40 ADA to the following fully automated vending address:</p>
                      
                  <div class=" p-1 mb-1 shadow text-center" style="background-color: #202020; text-shadow: none; border: 1px solid #666;">                             
                    <code>addr1vyy4gv0cv966txfktlvr9yenl5y02fcqsw2zuu9mqndnrsqayelrt</code>                 
                  </div>
                  
                  <p>Only pay one of the following exact amounts:</p>
                  <ol class="list-unstyled">
                    <li class="">1 NFT = 40 ADA</li>
                    <li class="">2 NFTs = 80 ADA</li>
                    <li class="">3 NFTs = 120 ADA</li>
                    <li class="">4 NFTs = 160 ADA</li>
                    <li class="">5 NFTs = 200 ADA</li>
                  </ol>
                  <p >Once paid, the NFTs will be sent to you automatically by NFT-Maker.io.</p><p>If there were no NFTs left or you paid an incorrect amount, you will be automatically refunded.</p>
                  <p>Please note that when using this method, this website won't show the status of the purchase as everything happens automatically between you and NFT-Maker.io.</p>
                  
                </div><!-- accordion-body -->
              </div><!--collapseOne -->
  </div><!-- accordion-item -->
  <div class="accordion-item">  
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    2. Manual, off-chain purchase
                  </button>
                </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#paymentType">
        <div class="accordion-body  bg-dark text-light">
                <p>Alternatively, you may pay on this website by entering below how many Blue Box NFTs you want and request a payment address for an interactive process.</p>
                
                <form class="form payment p-1 mb-1">
                <div class="d-grid gap-2 mt-4 col-6 mx-auto">
                  <label for="nftcount" class="small">Number of NFTs: {{nftcount}}</label>
                  <input type="range" class="form-range" min="1" max="5" id="nftcount" required v-model="nftcount" name="nftcount">                
                </div>
                
              <div class="d-grid gap-2 mt-2 col-6 mx-auto">
                <button 
                  :disabled="!!loading" 
                  @click="RequestPaymentAddress" 
                  class="btn btn-primary"
                  type="button">
                    <span v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </span>
                    <span v-else>
                      Request payment address
                    </span>
                </button>                     
              </div>             
                <p class="small">Price: {{ nftcount * nftprice}} ADA + transaction fees.</p>
                </form>
        </div> <!-- accordion-body -->
    </div><!-- collapseTwo -->
  </div><!-- accordion-item-->
</div><!-- accordion -->                
            </div><!-- /not yet submitted -->


            <!-- submitted and still polling -->
            <div v-if="submitted && polling">
              <div class="alert alert-primary d-flex align-items-center text-start" style="text-shadow: none;" role="alert">         
                <div class="ms-1">
                   {{nftcount}} Blue Box NFT<span v-if="nftcount>1">s have</span><span v-else> has</span> been reserved for you. You can now pay the NFTs using information below.
                </div>
              </div>

              <div class="p-1 mb-1 rounded shadow" style="background-color: #202020; text-shadow: none; border: 1px solid #666;">
                <h1>Waiting for your payment... 
                  <small>
                    <div class="spinner-border" style="" role="status"></div>
                  </small></h1>     
                <qrcode-vue :value="payment_info.pay_address" class="my-3"></qrcode-vue>
                <p class="lead">
                  You have {{ Math.floor((time_left_seconds % (1000 * 60 * 60)) / (1000 * 60)) }} minutes {{ Math.floor((time_left_seconds % (1000 * 60)) / 1000) }} seconds  to pay
                  <!-- until {{ new Date(payment_info.address_expires).toLocaleString()}}--><br> 
                  <strong class="fs-3">{{payment_info.pay_amount}}&nbsp;ADA</strong><br>to<br>
                  <strong  class="" style="color: yellow; font-size: 75%">{{payment_info.pay_address}}</strong>
                </p>                
              </div>

              <p>If you fail to pay before the time above, the NFTs will be released to the market.</p>
            </div><!-- /submitted and still polling -->
            


            <!-- FINITE STATE -->
            <!-- submitted and stopped polling -->
            <div v-if="submitted && !polling">
              <div v-if="api_error">
                <p>If you sent the requested ADA in time, it is very likely the transaction will go through in the blockchain and you will receive the tokens.</p>
                <p>If unsure, wait until the expiry time {{ new Date(payment_info.address_expires).toLocaleString()}}. If you do not receive the NFT, your ADA will be refunded to you automatically within a few hours.</p>
                  <div class="d-grid gap-2 my-2 col-6 mx-auto">
                    <button class="btn btn-primary" @click="Restart()">Start from beginning</button>
                  </div>
              </div>
              <div v-if="all_is_paid">
                <h1>Thank you for the purchase!</h1>
                <p class="lead">Your payment has been received and the NFT<span  v-if="nftcount>0">s are</span><span v-else> is</span> sent to your wallet.</p>               
                
                <div class="d-grid gap-2 my-2 col-6 mx-auto">
                  <button class="btn btn-primary" @click="Restart()">Buy more!</button>
                </div>
                <hr class="my-5">
                <h1 class="display-4">Your newly minted Blue Boxes</h1>
                <p class="lead">Your brand new NFTs can be viewed and downloaded below (warning: they are huge!). You can also view your NFTs in your wallet and at <a v-bind:href="'https://pool.pm/' + status.senderAddress" target="_blank">pool.pm</a>.</p>

                <div class="row row-cols-1 g-4" v-bind:class="{ 'row-cols-md-2': status.reservedNft.length > 1}">
                  <div class="col" v-for="item in status.reservedNft" :key="item.id">             
                      <div class="card mx-auto" style="max-width: 23em;">
                        <a :href="item.gatewayLink" target="_blank"><img :src="item.gatewayLink" class="card-img-top" :alt="item.name"></a>
                        <div class="card-body">
                          <h5 class="card-title text-dark" >{{item.name}}</h5>
                        </div>
                      </div>

                  </div><!-- col -->
                </div><!-- row -->
                                
                <p></p>

              </div>

              
            </div><!-- /submitted and stopped polling -->


            <div v-if="!all_is_paid">
              <h3 class="mt-5">Important!</h3>
              <ul class="text-start">
                <div v-if="polling">
                <li>Keep this page open until your transaction is complete. We will automatically update the status. Do not refresh the page.</li>                                    
                <li>Don't pay the same address more than once! If you want to buy more, wait until your transaction is finished. You will then be given an opportunity to make another purchase with a new address to send the ADA to.</li>                        
                </div>
                <li>Don't send ADA from any exchange, use a Daedalus, Nami or Yoroi Wallet to send funds. Also, make sure to send from a Shelly wallet.</li>
                <li>Pay exactly the amount above.</li>
                <li>If you need help, don't hesitate to contact us on Discord. We also can be contacted using boomer tech like <a href="mailto:markus@2600.quest">email</a> if that's your thing.</li>
              </ul>
            </div>
        </div>
    </div>
    <!-- end hero -->
  </main>
</template>
<script>
// https://www.bezkoder.com/vue-3-crud/
import NftApiService from "../services/NftApiService";
import QrcodeVue from 'qrcode.vue'

export default {
  setup() {
    
  },
  data () {
    return {
      nftcount: 1,
      nftprice: 40, // Only for convenience/display. Not used to calculate actual price to pay.
      payment_info: {
        pay_address: null,
        address_expires: null,
        pay_amount: 0,
      },      
      status: null,
      submitted: false,
      api_error: false,
      api_error_msg: null,
      qr_code_link: null,
      all_is_paid: false,
      loading: false,
      polling: null,
      purchase_time_expiry_countdown: null,
      time_left_seconds: null,
      success_message: null,
      polling_error_count: 0,
    }
  },
  components: {
    QrcodeVue,
  },
  methods: {
    RequestPaymentAddress() {
      this.api_error = false;
      this.api_error_msg = null;
      this.loading = true;
      var data = {
        nftcount: this.nftcount
      }
      NftApiService.getAddressForRandomNftSale(data)
          .then(response => {
            console.log(response);
            // {"paymentAddress": ".....", "expires": "2021-09-16T13:30:28.7302482Z", "adaToSend": "40"}
            // {"resultState": 1, "errorMessage": "No more NFT available", "errorCode": 10}
            
            if (!Object.prototype.hasOwnProperty.call(response.data, 'paymentAddress')) {
              
              var msg = response.data.errorMessage;

              if (response.data.errorCode && response.data.errorCode == 10) {
                msg = "Not enough NFTs left to mint. Request fewer NFTs or try again later.";
              }
              
              throw msg;
            }

            // TODO: Validate
            this.payment_info.pay_address = response.data.paymentAddress;
            this.payment_info.address_expires = response.data.expires;
            this.payment_info.pay_amount = response.data.adaToSend;
            this.loading = false;          

            this.submitted = true;
            this.polling = setInterval(() => {
              this.CheckAddress()
             }, 30000)

            this.updateTimer()
            this.purchase_time_expiry_countdown = setInterval(() => {
              this.updateTimer()
             }, 1000)


          })
          .catch(e => {
            console.log(e);   
            this.api_error = true;
            this.api_error_msg = e;
            this.loading = false;
          });
        
    },
    updateTimer() {
      let endTime = new Date(this.payment_info.address_expires).getTime();
      let now = new Date().getTime();
      this.time_left_seconds = endTime - now;
    },
    stopPolling() {
      clearInterval(this.polling);
      clearInterval(this.purchase_time_expiry_countdown)
      this.purchase_time_expiry_countdown = null;
      this.polling = null;
    },
    Restart() {
      this.payment_info = {
        pay_address: null,
        address_expires: null,
        pay_amount: 0,
      };
      this.status = null;
      this.submitted = false;
      this.api_error = false;
      this.api_error_msg = null;
      this.qr_code_link = null;
      this.all_is_paid = false;
      this.loading = false;
      this.polling = null;
      this.success_message = null;
    },
    CheckAddress() {

      console.log('CheckAddress()')

      if (this.payment_info.pay_address) {
        console.log('checking status for pay address', this.payment_info.pay_address)
        NftApiService.checkAddress(this.payment_info.pay_address)
          .then(response => {

              console.log(response);            
              /*
              Reserved state looks like this (where are states documented?) 
              
              {"state": "active", "lovelace": 0, "hasToPay": 30000000, "payDateTime": null, "expiresDateTime": "2021-09-17T10:28:28Z", "transaction": null, "senderAddress": null, "reservedNft": [{"id": 275759, "name": null, "ipfsLink": null, "gatewayLink": null, "state": null, "minted": false, "policyId": null, "assetId": null, "assetname": null, "fingerprint": null, "initialMintTxHash": null, "series": null, "tokenamount": 1}]}

              EXPIRED response looks like this:
              {state: 'expired', lovelace: 0, hasToPay: 30000000, payDateTime: null, expiresDateTime: '2021-09-17T11:38:48Z', …}

              PAID:

              {
                "state": "paid", 
                "lovelace": 6500000, 
                "hasToPay": 6500000, 
                "payDateTime": "2021-09-17T13:04:39Z", 
                "expiresDateTime": null, 
                "transaction": "0621e80c9bfbd0ad266cd39963e300bef432450b47d7139e448fdf64c251e073",
                "senderAddress": "....", 
                "reservedNft": [
                  {
                    "id": 276402, 
                    "name": "BlueBox6", 
                    "ipfsLink": "ipfs://Qmd43L4RTtws25DYTtAHx5BgsXz4UVhHGhJg5sBmFZ7iJo", 
                    "gatewayLink": "https://gw2.easy-ipfs.com/ipfs/Qmd43L4RTtws25DYTtAHx5BgsXz4UVhHGhJg5sBmFZ7iJo", 
                    "state": "sold", 
                    "minted": true, 
                    "policyId": null, 
                    "assetId": "f1cf2d25fd0bd9104a0c690dc906c35ec0991b3ba0d5653af621c109426c7565426f7836", 
                    "assetname": "426c7565426f7836", 
                    "fingerprint": "asset1llhxw2g4yn5a2v6ndhsatjdkk7shp43uezgp9c", 
                    "initialMintTxHash": "a701b9ce9be61b03f2468467ebf027d0c942b844c14d35ba6babae77dd8e447e", 
                    "series": null, 
                    "tokenamount": 1
                  }
                ]
              }

               Actual errors look like this
                {
                  errorCode: 50
                  errorMessage: "Address not known"
                  resultState: 1
                }
              */
             /*
              response.data = {
                "state": "paid", 
                "lovelace": 6500000, 
                "hasToPay": 6500000, 
                "payDateTime": "2021-09-17T13:04:39Z", 
                "expiresDateTime": null, 
                "transaction": "0621e80c9bfbd0ad266cd39963e300bef432450b47d7139e448fdf64c251e073",
                "senderAddress": "....", 
                "reservedNft": [
                  {
                    "id": 276402, 
                    "name": "BlueBox6", 
                    "ipfsLink": "ipfs://Qmd43L4RTtws25DYTtAHx5BgsXz4UVhHGhJg5sBmFZ7iJo", 
                    "gatewayLink": "https://gw2.easy-ipfs.com/ipfs/Qmd43L4RTtws25DYTtAHx5BgsXz4UVhHGhJg5sBmFZ7iJo", 
                    "state": "sold", 
                    "minted": true, 
                    "policyId": null, 
                    "assetId": "f1cf2d25fd0bd9104a0c690dc906c35ec0991b3ba0d5653af621c109426c7565426f7836", 
                    "assetname": "426c7565426f7836", 
                    "fingerprint": "asset1llhxw2g4yn5a2v6ndhsatjdkk7shp43uezgp9c", 
                    "initialMintTxHash": "a701b9ce9be61b03f2468467ebf027d0c942b844c14d35ba6babae77dd8e447e", 
                    "series": null, 
                    "tokenamount": 1
                  },
                  {
                    "id": 276402, 
                    "name": "BlueBox6", 
                    "ipfsLink": "ipfs://Qmd43L4RTtws25DYTtAHx5BgsXz4UVhHGhJg5sBmFZ7iJo", 
                    "gatewayLink": "https://gw2.easy-ipfs.com/ipfs/Qmd43L4RTtws25DYTtAHx5BgsXz4UVhHGhJg5sBmFZ7iJo", 
                    "state": "sold", 
                    "minted": true, 
                    "policyId": null, 
                    "assetId": "f1cf2d25fd0bd9104a0c690dc906c35ec0991b3ba0d5653af621c109426c7565426f7836", 
                    "assetname": "426c7565426f7836", 
                    "fingerprint": "asset1llhxw2g4yn5a2v6ndhsatjdkk7shp43uezgp9c", 
                    "initialMintTxHash": "a701b9ce9be61b03f2468467ebf027d0c942b844c14d35ba6babae77dd8e447e", 
                    "series": null, 
                    "tokenamount": 1
                  }
                ]
              };
              */

              // handle nftmaker API error scenarios:
              // - address not known (has expired or was cancelled)
              // - api key or project id was incorrect
              if (Object.prototype.hasOwnProperty.call(response.data, 'resultState') && response.data.resultState == 1) {
                  this.stopPolling()
                  this.api_error = true;
                  if (response.data.errorCode == 50) {
                    this.api_error_msg = "Error: Payment address has been released. This typically happens if you cancel the purchase.";
                  }
              }

              // Let's update our internal state
              this.status = {
                state: response.data.state,
                lovelace: response.data.lovelace,
                hasToPay: response.data.hasToPay,
                payDateTime: response.data.payDateTime,
                expiresDateTime: response.data.expiresDateTime,
                transaction: response.data.transaction,
                senderAddress: response.data.senderAddress,
                reservedNft: response.data.reservedNft,
              };


              if (this.status.state == "active") {
                // still polling, all is good
              } else if (this.status.state == "paid") {
                // The order is successfully paid. 
                this.stopPolling();
                this.all_is_paid = true;
              
              } else if (this.status.state == "expired") {
                this.stopPolling()
                this.api_error = true;
                this.api_error_msg = "The payment expired. If you sent any ADA to the payment address, the ADA will be returned to you automatically."
              } 

          })
          .catch(e => {            
            console.log(e);
            this.api_error = true
            this.api_error_msg = e

            this.stopPolling()
          });
      } // /if payment_address has been set
    }
  },
  beforeUnmount() {
    this.stopPolling()
  }
}
</script>