<template>
  <main class="px-3">

    <!-- hero -->
    <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
        <div class="col-md-8 col-lg-65 mx-auto my-2 mb-5">
          <img src="../assets/img/TransparentBG_01_500.png" alt="An image of Blue Box NFT" class="img-fluid">
          <h1 class="display-4 fw-normal">Welcome to the 2600 NFT Project</h1>
          <p class="lead fw-normal">Join us on a phreaking adventure on Cardano blockchain. What will happen when you activate our Blue Box NFT on the chain?</p>
          
          <a href="https://twitter.com/2600_nft" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="text-light bi bi-twitter" viewBox="0 0 16 16">
      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
    </svg></a>
                
          <a href="https://discord.gg/bhw9KkvcSb" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="text-light bi bi-discord" viewBox="0 0 16 16">
      <path d="M6.552 6.712c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z"/>
      <path d="M13.36 0H2.64C1.736 0 1 .736 1 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072l-.424-1.48 1.024.952.968.896L15 16V1.648C15 .736 14.264 0 13.36 0zm-3.088 10.448s-.288-.344-.528-.648c1.048-.296 1.448-.952 1.448-.952-.328.216-.64.368-.92.472-.4.168-.784.28-1.16.344a5.604 5.604 0 0 1-2.072-.008 6.716 6.716 0 0 1-1.176-.344 4.688 4.688 0 0 1-.584-.272c-.024-.016-.048-.024-.072-.04-.016-.008-.024-.016-.032-.024-.144-.08-.224-.136-.224-.136s.384.64 1.4.944c-.24.304-.536.664-.536.664-1.768-.056-2.44-1.216-2.44-1.216 0-2.576 1.152-4.664 1.152-4.664 1.152-.864 2.248-.84 2.248-.84l.08.096c-1.44.416-2.104 1.048-2.104 1.048s.176-.096.472-.232c.856-.376 1.536-.48 1.816-.504.048-.008.088-.016.136-.016a6.521 6.521 0 0 1 4.024.752s-.632-.6-1.992-1.016l.112-.128s1.096-.024 2.248.84c0 0 1.152 2.088 1.152 4.664 0 0-.68 1.16-2.448 1.216z"/>
    </svg></a> 
        </div>
    </div>
    <!-- end hero -->

        <div class="container">
            <div class="row text-start ">
              <div class="col">           
                <section>

                <h1 class="display-1 my-4" id="about">About the Blue Box NFTs</h1>

                <p>The Blue Boxes are one of the most original and innovative NFT projects on Cardano.</p>
                
                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-4 py-5">
                    <div class="col d-flex align-items-start">                      
                      <svg xmlns="http://www.w3.org/2000/svg"  width="1.75em" height="1.75em" fill="currentColor" class="bi bi-image-fill text-light flex-shrink-0 me-3" viewBox="0 0 16 16">
                        <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
                      </svg>
                      <div>
                        <h4 class="fw-bold mb-0">Functional art pieces</h4>
                        <p>Blue Boxes are functional art pieces implemented as non-fungible tokens (NFTs) on Cardano blockchain.</p>
                      </div>
                    </div>


                    <div class="col d-flex align-items-start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" fill="currentColor" class="bi bi-code-square text-light flex-shrink-0 me-3" viewBox="0 0 16 16">
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
          <path d="M6.854 4.646a.5.5 0 0 1 0 .708L4.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0zm2.292 0a.5.5 0 0 0 0 .708L11.793 8l-2.647 2.646a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708 0z"/>
        </svg>                        
                      <div>
                        <h4 class="fw-bold mb-0">Hack embedded</h4>
                        <p>Every Blue Box NFT has a working computer program source code included that will, once activated, allow the NFT holder to 
                    <span
                      title="Dear FBI, we are not really hacking anything. This is just a game mechanic." 
                      data-bs-toggle="tooltip"
                      data-bs-placement="top" 
                      style="border-bottom: 2px dotted #eee;">hack the blockchain</span> for potential rewards. </p>
                      </div>
                    </div>

                    <div class="col d-flex align-items-start">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" fill="currentColor" class="bi bi-play-btn text-light flex-shrink-0 me-3" viewBox="0 0 16 16">
                        <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                        </svg>
                      <div>
                        <h4 class="fw-bold mb-0">Activation code</h4>
                        <p>Your Blue Box comes with an activation code that needs to be used when activating the hack. Among the 2,600 NFTs, there are 4 special Blue Boxes with super powerful activation codes!</p>
                      </div>
                    </div>

                    <div class="col d-flex align-items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" fill="currentColor" class="bi bi-list-check text-light flex-shrink-0 me-3" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                    </svg>
                      <div>
                        <h4 class="fw-bold mb-0">A 2,600 NFT Project </h4>
                        <p>True to our project, only 2,600 Blue Box NFTs will be minted, ever!</p>
                      </div>
                    </div>



     
                    <div class="col d-flex align-items-start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" fill="currentColor" class="bi bi-badge-3d text-light flex-shrink-0 me-3" viewBox="0 0 16 16">
                      <path d="M4.52 8.368h.664c.646 0 1.055.378 1.06.9.008.537-.427.919-1.086.919-.598-.004-1.037-.325-1.068-.756H3c.03.914.791 1.688 2.153 1.688 1.24 0 2.285-.66 2.272-1.798-.013-.953-.747-1.38-1.292-1.432v-.062c.44-.07 1.125-.527 1.108-1.375-.013-.906-.8-1.57-2.053-1.565-1.31.005-2.043.734-2.074 1.67h1.103c.022-.391.383-.751.936-.751.532 0 .928.33.928.813.004.479-.383.835-.928.835h-.632v.914zm3.606-3.367V11h2.189C12.125 11 13 9.893 13 7.985c0-1.894-.861-2.984-2.685-2.984H8.126zm1.187.967h.844c1.112 0 1.621.686 1.621 2.04 0 1.353-.505 2.02-1.621 2.02h-.844v-4.06z"/>
                      <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    </svg>
                      <div>
                        <h4 class="fw-bold mb-0">Gorgeous 3D models</h4>
                        <p>The NFTs are professional-made, high-resolution 4000 * 4000 px renders of a 3D model inspired by actual blue boxes.</p>
                      </div>
                    </div>

                    <div class="col d-flex align-items-start">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" fill="currentColor" class="bi bi-calendar2-event-fill text-light flex-shrink-0 me-3" viewBox="0 0 16 16">
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zM11.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
                      </svg>
                      <div>
                        <h4 class="fw-bold mb-0">Additional value for holders</h4>
                        <p>To reward our community, existing NFT holders will have priority in sale events of future 2,600 NFT projects.</p>
                      </div>
                    </div>
              </div>

              <figure class="figure float-end ms-3">
                  <img src="../assets/img/BlueBoxNFT.jpg" class="figure-img img-fluid" alt="An example of Blue Box NFT" style="max-width: 250px">
                  <figcaption class="figure-caption text-start" style="max-width:250px">Sample of Blue Box NFT #2599.</figcaption>
                </figure>

                <p>The renders are true to the yesteryear look and self-made feel of the blue boxes while still looking super cool. Our artist did a stellar job here!</p>

                <p>Each Blue Box is individually numbered from 0x000000000000 to 0x101000100111, and each Box can only be owned by a single person on the blockchain. 10 regular Blue Boxes have been reserved for giveaways/airdrops.</p>

                <p>As an NFT owner, you will have full rights to use them as you wish. You can print them, you can frame them, you can go to your favourite electrician and have a real-life blue box made for you.
                   You may even use the images commercially; we're cool like that. And even though we'd hate to see you leave our 2,600 NFT Club, you are always allowed to sell your NFT.</p>

                <p>Our policy id is <code>571e017f4bc8178c929ab97c4892b71f2ead02e38fc1cf48b58ff93f</code>. Always check it to make sure you don't get scammed.</p>
              


                <h2 class="clearfix">Okay, but what exactly are Blue Boxes?</h2>
                
                <p>Back in the 60ies and 70ies when landline phones were still a thing and phone lines were controlled with in-band signaling, 
                  the <a class="text-light" href="https://en.wikipedia.org/wiki/Blue_box">blue boxes</a> were built to generate 
                  a <span title="This is a remarkable number and also the number of Blue Box NFTs we will be dropping.">2600 Hz</span> tone that would make the telephone networks think the line was idle. 
                  This allowed the hackers -- or <em>phreakers</em> as they used to call themselves -- to do shady things such as make long-distance calls for free by first 
                  calling a toll-free local number.</p>

                <p>Some of the most famous phreakers were <strong>Steve Wozniak</strong> and <strong>Steve Jobs</strong>, who <a href="http://www.paulgraham.com/bluebox.html" class="text-light">made 
                and sold blue boxes</a> before founding Apple. Who would've thought?</p>


                <h2>Sounds cool, can you tell more about this project?</h2>


                <p>We believe it's worth giving a nod to computer science history and early geek culture. The 2600 NFT Project's goal is to introduce new audiences to old-school 
                  hacking and geek phenomena through bringing cool gadgets alive as NFTs. We are particularly interested in following the Cardano smart contract development and finding new ways to add exciting utility 
                  to the NFTs.</p>

                <figure class="ms-lg-5 ms-1 p-2">
                  <blockquote class="blockquote" style="">
                    <p>"We are dreamers, shapers, singers, and makers. We study the mysteries of laser and circuit, crystal and scanner, holographic demons and invocation of equations."</p>
                  </blockquote>
                  <figcaption class="blockquote-footer"  style="">
                  Elric the technomage, <cite title="Source Title">Babylon 5, Season 2: The Coming of Shadows</cite>
                  </figcaption>
                </figure>

                <p>We are all still early pioneers trying to figure out what we can do with blockchains. Our curiosity to understand how things work has many 
                  similarities with those early pioneers playing around with telephone networks. We believe these are the
                   early experiments people will be writing books about once we learn what the technology is capable of.</p>

        
                
              </section>

        

                <section class="my-5">

                <h1 class="display-1 my-4"  id="roadmap">Project Roadmap</h1>
        
                <p>If you want to hear more about our plans, 
                head over to our <a class="text-light" href="https://discord.gg/qKrjDXsF" target="_blank">Discord</a> where we are more than happy to talk about our vision with you.</p>

                <div class="table-responsive-sm">
                <table class="table table-dark table-hover roadmap">
                  <tbody>
                    <tr>
                      <td><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#60d060" class="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg></td>
                      <th><code>FirstPhase.start();</code></th>
                      <td>Social media accounts, website v1 published with socials and a teaser. Finalised 3D model and renders.</td>
                    </tr>
                    <tr>
                        <td><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#60d060" class="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg></td>
                      <th><code>twitter.followers.count() > 0</code></th>
                      <td>Mom, I'm now an influencer! Release website v2 with more details.</td>
                      </tr>
                    <tr>
                        <td><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#60d060" class="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg></td>                      
                      <th><code>ApiResponse.status_code === 200</code></th>
                      <td><strong>NFT sales start!</strong> As NFT sales promotion, we will be doing our first giveaway to the community.</td>
                    </tr>
                    <tr>    
                    <td><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#60d060" class="bi bi-check" viewBox="0 0 16 16">
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                        </svg></td>
                      <th><code>Nfts.sold_pct >= 10%</code></th>
                      <td>The 2600 Club member area v1 opened. Official Blue Box mixtape exclusive to our community will be published. We’re going to honour legendary scene musicians, early electronic music pioneers and there will also be a few surprises thrown in the mix.</td>
                    </tr>
                    <tr>
                      <td><div class="spinner-grow" role="status" title="Happening right now!"><span class="visually-hidden">In progress...</span></div></td>
                      <th><code>nfts_sold >= 30%</code></th>
                      <td>Some of the Blue Boxes will be airdropped to early followers and active community members.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <th><code>nfts_sold >= 50%</code></th>
                      <td>We are halfway there! Second community-driven decision in the project.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <th><code>nfts_sold >= 80%</code></th>
                      <td>We’re going to be legends. The official merch line with Blue Box hoodies (because every hacker needs a hoodie), T shirts, phone covers released. These will be only available for the 2600 Club members.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <th><code>nfts_sold == 100%</code></th>
                      <td>Another giveaway to Twitter followers. Reveal first utility (details on how to activate the Blue Boxes on-chain).</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <th><code>SOLD_OUT && date >= 4Q_2021 ? SecondPhase.start() : hodl()</code></th>
                      <td>The 2600 Club member area v2 opened. The Blue Boxes NFTs can be activated bringing on-chain rewards to holders.</td>
                    </tr>
                  </tbody>
                </table>
                </div>

                <div>
                  <p>The story doesn't end here, these are just our first steps! There are so many cool geek/hacking gadgets and tools to introduce. 
                    Most importantly, we hope to make this a community effort, and listening to you guys will have a big impact on how we will move from here.</p>
                </div>

              </section>

          

                <section class="my-5">

                <h1 class="display-1 my-4" id="team">Our team</h1>

                <p>Notice how I've spoken about "us" the whole time in this document? I think this reflects the project's values and goals as I wish to create a strong community to discover what the next NFT and utility will be. 
                  But when it comes down to coding, UI/UX design, concept art etc. there's just me, Mr. Morden.</p>

                <div class="row mt-5">
                  <div class="col-lg-6 ">
                    <img src="../assets/img/morden.jpg" width="140" height="140" style="display: inline-block; object-fit: cover; border-radius: 50%;"
                       alt="Profile picture of Mr. Morden. Yes, I am a Babylon 5 fan.">

                    <h5>Mr. Morden</h5>
                    <p>Geek, coder, father, husband. It all started with borrowing C64 coding books from the library as a child and trying to create something new. I'm playing around and investing in crypto and super-hyped about Cardano, in particular!</p>
                  </div><!-- /.col-lg-6 -->

                  <div class="col-lg-6">
                    <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#777"></rect><text x="50%" y="50%" fill="#777" dy=".3em">?</text></svg>

                    <h5>This could be you</h5>
                    <p>I am looking for like-minded tinkerers who wish to dig into Cardano smart contracts and DeFi.</p>
                  </div><!-- /.col-lg-6 -->
                </div>
              </section>
              </div>
        </div>
      </div>
    </main>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js"
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
  name: 'Home',
  mounted() {
      Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
      .forEach(tooltipNode => new Tooltip(tooltipNode))
  }
}

</script>
<style>
.roadmap>:not(caption)>*>* { padding: 1em !important;}
</style>
