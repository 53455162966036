<template>
  <header class="mb-auto">
    <a href="/" class="float-md-start mb-0 d-flex align-items-center text-dark text-decoration-none">
      <img src="../assets/img/logotext.png" height="48" alt="The 2600 NFT Project logo">
    </a>
    <nav class="nav nav-masthead justify-content-center float-md-end">
        <router-link class="nav-link" active-class="active" to="/">Home</router-link>        
        <!--router-link class="nav-link"  to="/#about" >About</router-link>
        <router-link class="nav-link"  to="/#roadmap" >Roadmap</router-link>
        <router-link class="nav-link"  to="/#team">Team</router-link-->
        <router-link class="nav-link" active-class="active"   to="/buy">Buy</router-link>
        <a href="https://club.2600.quest" class="nav-link">2600 NFT Club <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
</svg></a>
      </nav>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>
