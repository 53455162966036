<template>
  <main class="px-3">
    <!-- hero -->
    <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
        <div class="col-md-7 col-lg-65 mx-auto my-2 mb-5">
            <h1 class="display-4 fw-normal">Page not found</h1>
            <p class="lead fw-normal">Sorry, the requested page couldn't be found.</p>
            <p>Would you like to start from our <router-link to="/" class="text-light">home page</router-link>?</p>
        </div>
    </div>
  </main>
</template>
<script>
export default {
  name: 'NotFound',
  components: {
  },
}
</script>
